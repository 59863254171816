import { createI18n } from 'vue-i18n';
import en from './locales/en.json';
import fr from './locales/fr.json';
import nl from './locales/nl.json';

function loadLocaleMessages() {
  const locales = { fr, nl, en };
  const messages = {};
  Object.keys(locales).forEach(key => {
    messages[key] = locales[key];
  });
  return messages;
}

export default createI18n({
  locale: 'fr',
  fallbackLocale: 'fr',
  legacy: false,
  messages: loadLocaleMessages(),
});