<template>
  <section id="hero" class="hero">
    <div class="carousel">
      <img v-for="(image, index) in images" :src="image" :key="index" :class="{ active: index === currentImage }" />
    </div>
    <div class="logo-container">
      <img src="@/assets/logo_white_text.svg" alt="Logo" class="logo" />
    </div>
    <svg style="width: 0; height: 0;">
      <filter id="svg-shadow" x="-50%" y="-50%" width="200%" height="200%">
        <feDropShadow dx="0" dy="0" stdDeviation="8" flood-color="rgba(0,0,0,0.9)" />
      </filter>
    </svg>
  </section>
</template>

<script setup>
import { ref, onMounted } from 'vue';

const images = [
  require('@/assets/hero_1.jpg'),
  require('@/assets/hero_2.jpg'),
  require('@/assets/hero_3.jpg'),
  require('@/assets/hero_4.jpg'),
  require('@/assets/hero_5.jpg')
];

const currentImage = ref(Math.floor(Math.random() * images.length));

const cycleImages = () => {
  currentImage.value = (currentImage.value + 1) % images.length;
};

onMounted(() => {
  setInterval(cycleImages, 10000);
});
</script>

<style scoped>
.hero {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  position: relative;
  width: 100%;
  height: 64vh; /* 80vh - 20% */
  overflow: hidden;
}

.carousel {
  width: 100%;
  height: 100%;
  position: relative;
}

.carousel img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 0 23%;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.carousel img.active {
  opacity: 1;
}

.logo-container {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logo {
  width: 60rem; /* Adjust the size of the logo as needed */
  height: auto;
  filter: url(#svg-shadow); /* Apply the shadow filter */
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

@media (max-width: 767px) {
  .carousel img {
    object-position: center;
  }

  .logo {
    width: 24rem; /* Adjust the size of the logo for smaller screens */
  }

  .logo-container {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
