<template>
  <header class="header" ref="header">
    <div class="nav-container">
      <div class="logo">
        <img src="@/assets/logo_black_text.svg" alt="Singing Molenbeek Logo" />
      </div>
      <nav :class="{ open: isMenuOpen }">
        <ul>
          <li><a href="#hero" @click="resetAnimation">{{ $t('navbar.home') }}</a></li>
          <li><a href="#about" @click="resetAnimation">{{ $t('navbar.about') }}</a></li>
          <li><a href="#posts" @click="resetAnimation">{{ $t('navbar.news') }}</a></li>
          <li><a href="#gallery" @click="resetAnimation">{{ $t('navbar.gallery') }}</a></li>
          <li><a href="#contact" @click="resetAnimation">{{ $t('navbar.contact') }}</a></li>
          <li><LocaleSwitcher /></li>
        </ul>
      </nav>
      <div class="menu-icon" @click="toggleMenu">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </header>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import LocaleSwitcher from './LocaleSwitcher.vue';

const isMenuOpen = ref(false);
const header = ref(null);

const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value;
  if (isMenuOpen.value) {
    document.addEventListener('click', handleClickOutside);
  } else {
    document.removeEventListener('click', handleClickOutside);
  }
};

const closeMenu = () => {
  isMenuOpen.value = false;
  document.removeEventListener('click', handleClickOutside);
};

const handleClickOutside = (event) => {
  if (header.value && !header.value.contains(event.target)) {
    closeMenu();
  }
};

const handleClickInside = (event) => {
  event.stopPropagation();
};

onMounted(() => {
  document.addEventListener('click', handleClickOutside);
  if (header.value) {
    header.value.addEventListener('click', handleClickInside);
  }
});

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside);
  if (header.value) {
    header.value.removeEventListener('click', handleClickInside);
  }
});
</script>

<style scoped>
.header {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px;
  background-color: #f8f9fa;
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.logo img {
  height: 3.7rem; /* Adjust the height as needed */
  padding: 0.4rem 0.3rem 0.2rem 1rem;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.menu-icon {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.menu-icon span {
  width: 25px;
  height: 3px;
  background-color: #333;
  margin: 4px 0;
}

nav ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

nav ul li {
  margin: 0 15px;
  font-size: larger;
  position: relative;
}

nav ul li a {
  text-decoration: none;
  color: #333;
  font-weight: bold; /* Make the links bold */
  position: relative;
  display: inline-block;
  padding-bottom: 5px;
  transition: color 0.3s;
}

nav ul li a::after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 2px;
  background-color: #333;
  transition: width 0.3s;
}

nav ul li a:hover::after {
  width: 100%;
}

nav ul li a:focus::after,
nav ul li a:active::after {
  animation: resetUnderline 0.3s forwards;
}

@keyframes resetUnderline {
  to {
    width: 0;
  }
}

@media (max-width: 768px) {
  .menu-icon {
    display: flex;
    margin-right: 0.5rem;
  }

  .logo img {
    height: 2.5rem; /* Adjust the height as needed */
  }

  nav {
    position: absolute;
    top: 60px;
    right: 0;
    background-color: #f8f9fa;
    width: 100%;
    display: none;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  nav.open {
    display: flex;
  }

  nav ul {
    flex-direction: column;
    width: 100%;
  }

  nav ul li {
    margin: 15px 0;
    text-align: center;
  }

  .header {
    justify-content: space-between;
  }
}
</style>
