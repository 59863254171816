<template>
  <footer id="footer" class="footer">
    <div class="footer-content">
      <div class="social-media">
        <a href="https://facebook.com/singingmolenbeek" target="_blank">
          <img src="@/assets/facebook-icon.svg" alt="Facebook">
        </a>
        <a href="https://instagram.com/singingmolenbeek" target="_blank">
          <img src="@/assets/instagram-icon.svg" alt="Instagram">
        </a>
        <a href="https://vimeo.com/user62138394" target="_blank">
          <img src="@/assets/vimeo-icon.svg" alt="Vimeo">
        </a>
        <a href="mailto:contact@singingmolenbeek.com">
          <img src="@/assets/email-icon.svg" alt="Email">
        </a>
      </div>
      <div class="footer-info">
        <p>{{ $t('footer.copyright') }}</p>
        <p>{{ $t('footer.contact') }}<a href="mailto:contact@singingmolenbeek.com">contact@singingmolenbeek.com</a></p>
      </div>
    </div>
  </footer>
</template>

<script setup>
</script>

<style scoped>
.footer {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  margin-top: 10rem;
  padding: 20px;
  background-color: #333;
  color: white;
  text-align: center;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.social-media {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.social-media img {
  width: 30px;
  height: auto;
  transition: transform 0.3s ease;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.social-media img:hover {
  transform: scale(1.1);
}

.footer-info {
  text-align: center;
}

.footer-info p {
  margin: 5px 0;
}

.footer-info a {
  color: #007bff;
  text-decoration: none;
}

.footer-info a:hover {
  text-decoration: underline;
}
</style>
