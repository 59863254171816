<template>
  <section id="contact" class="contact-section">
    <h2>{{ $t('contact.title') }}</h2>
    <form @submit.prevent="sendEmail">
      <div class="form-group">
        <label for="name">{{ $t('contact.name') }}</label>
        <input type="text" id="name" v-model="name" required />
      </div>
      <div class="form-group">
        <label for="email">{{ $t('contact.address') }}</label>
        <input type="email" id="email" v-model="email" required />
      </div>
      <div class="form-group">
        <label for="message">{{ $t('contact.message') }}</label>
        <textarea id="message" v-model="message" required></textarea>
      </div>
      <button type="submit">{{ $t('contact.send') }}</button>
    </form>
  </section>
</template>

<script setup>
import { ref } from 'vue';

const name = ref('');
const email = ref('');
const message = ref('');

const sendEmail = () => {
  const subject = `Message from ${name.value}`;
  const body = `Name: ${name.value}\nEmail: ${email.value}\n\n${message.value}`;
  const mailtoLink = `mailto:contact@singingmolenbeek.be?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  
  window.location.href = mailtoLink;
};
</script>

<style scoped>
.contact-section {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  max-width: 600px;
  margin: 13rem auto 0 auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

h2 {
  margin-bottom: 20px;
  font-size: 1.5rem;
  text-align: center;
}

form {
  padding: 0 20px; /* Ensure even padding on left and right */
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

input, textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  box-sizing: border-box; /* Ensure padding is included in width */
}

textarea {
  resize: vertical;
  min-height: 100px;
}

button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-sizing: border-box; /* Ensure padding is included in width */
}

button:hover {
  background-color: #0056b3;
}

@media (max-width: 767px) {
  form {
    padding: 0;
  }
  .contact-section {
    margin: 1rem;
  }
}
</style>
