<template>
  <div class="locale-switcher">
    <div @click="toggleDropdown" class="locale-selected">
      <img :src="localeFlags[currentLocale]" :alt="currentLocale" class="flag"/> {{ currentLocale.toUpperCase() }}
    </div>
    <ul v-if="dropdownOpen" class="locale-options">
      <li v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" @click="setLocale(locale)">
        <img :src="localeFlags[locale]" :alt="locale" class="flag"/> {{ locale.toUpperCase() }}
      </li>
    </ul>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

import enFlag from '@/assets/gb.svg';
import frFlag from '@/assets/fr.svg';
import nlFlag from '@/assets/nl.svg';

const { locale } = useI18n();
const dropdownOpen = ref(false);

const localeFlags = {
  en: enFlag,
  fr: frFlag,
  nl: nlFlag,
};

const currentLocale = ref(locale.value);

const toggleDropdown = () => {
  dropdownOpen.value = !dropdownOpen.value;
};

const setLocale = (newLocale) => {
  locale.value = newLocale;
  currentLocale.value = newLocale;
  dropdownOpen.value = false;
};
</script>

<style scoped>
.locale-switcher {
  position: relative;
  display: inline-block;
}

.locale-selected {
  padding: 5px 15px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.locale-options {
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  z-index: 10;
}

.locale-options li {
  padding: 5px 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.locale-options li:hover {
  background-color: #f0f0f0;
}

.flag {
  width: 20px;
  height: 14px;
  margin-right: 10px;
}
</style>
