<template>
  <section id="about" class="about">
    <h2>{{ $t('about.title') }}</h2>
    <div class="description">
      <div class="content-block" v-for="(paragraph, index) in paragraphs" :key="index">
        <div class="image-container" :class="index % 2 === 0 ? 'left' : 'right'">
          <img :src="images[index]" :alt="'Image ' + (index + 1)" />
        </div>
        <p>{{ paragraph }}</p>
      </div>
    </div>
  </section>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import img1 from '@/assets/about_1.jpeg';
import img2 from '@/assets/about_2.jpeg';
import img3 from '@/assets/about_3.jpeg';
import img4 from '@/assets/about_4.jpeg';

const { t } = useI18n();

const paragraphs = computed(() => [
  t('about.content.description.p1'),
  t('about.content.description.p2'),
  t('about.content.description.p3'),
  t('about.content.description.p4')
]);

const images = [
  img1,
  img2,
  img3,
  img4
];
</script>

<style scoped>
.about {
  padding: 50px 20px;
  background-color: rgba(248, 249, 250, 0); /* Semi-transparent background */
  text-align: center;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

h2 {
  margin-bottom: 20px;
  font-size: 2rem;
  color: #333;
}

.description {
  max-width: 57rem;
  margin: 0 auto;
  font-size: 1.1rem;
  line-height: 1.6;
  color: #555;
}

.content-block {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap; /* Ensures that the items wrap on small screens */
}

.image-container {
  flex: 0 0 350px;
  margin-right: 20px;
}

.image-container.right {
  order: 1;
  margin-right: 0;
  margin-left: 3rem;
}

.image-container.left {
  margin-right: 3rem;
}
.image-container img {
  width: 100%;
  border-radius: 10px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

p {
  flex: 1;
  text-align: justify;
}

/* Responsive styles */
@media (max-width: 768px) {
  .content-block {
    flex-direction: column; /* Stack items vertically on small screens */
    align-items: flex-start; /* Align items to the start */
  }

  .image-container {
    flex: 0 0 100%; /* Image takes full width */
    margin-right: 0 !important; /* Reset margin */
    margin-bottom: 20px; /* Add margin at the bottom */
  }

  .image-container.right {
    order: 0; /* Reset order */
    margin-left: 0; /* Reset margin */
  }

  p {
    width: 100%; /* Paragraph takes full width */
  }
}
</style>
