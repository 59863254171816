<template>
  <section id="videos" class="videos">
    <h2>{{ $t('videos.title') }}</h2>
    <div class="carousel-wrapper">
      <button @click="prevSlide" class="carousel-control left">❮</button>
      <div class="carousel-container" ref="videoCarousel">
        <div class="carousel-track-container" @touchstart="handleTouchStart" @touchend="handleTouchEnd">
          <div class="carousel-track" :style="{ transform: `translateX(-${currentSlide * 100}%)` }">
            <div v-for="(video, index) in videos" :key="index" class="carousel-slide">
              <template v-if="video.type === 'youtube'">
                <iframe :src="getYouTubeEmbedUrl(video.url)" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
              </template>
              <template v-else>
                <video controls :src="video.url" :poster="video.poster" class="local-video"></video>
              </template>
            </div>
          </div>
        </div>
      </div>
      <button @click="nextSlide" class="carousel-control right">❯</button>
    </div>
  </section>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';

const videos = [
  { url: require('@/assets/video_1.mp4'), poster: require('@/assets/video_1_thumb.jpg'), type: 'local' }, // Local video file with poster
  { url: 'https://www.youtube.com/watch?v=jWS9j9uz4X8', type: 'youtube' },
  { url: 'https://www.youtube.com/watch?v=778usP0h1_o', type: 'youtube' },
  { url: 'https://www.youtube.com/watch?v=_8VNlprEhp0', type: 'youtube' },
  // Add more video links
];

const getYouTubeEmbedUrl = (url) => {
  const videoId = url.split('v=')[1];
  return `https://www.youtube.com/embed/${videoId}`;
};

const currentSlide = ref(0);
const startX = ref(0);

const slidesPerPage = computed(() => (window.innerWidth < 768 ? 1 : 3));
const totalSlides = computed(() => Math.ceil(videos.length / slidesPerPage.value));

const prevSlide = () => {
  if (currentSlide.value > 0) {
    currentSlide.value--;
  } else {
    currentSlide.value = totalSlides.value - 1;
  }
};

const nextSlide = () => {
  if (currentSlide.value < totalSlides.value - 1) {
    currentSlide.value++;
  } else {
    currentSlide.value = 0;
  }
};

const handleTouchStart = (e) => {
  startX.value = e.touches[0].clientX;
};

const handleTouchEnd = (e) => {
  const endX = e.changedTouches[0].clientX;
  if (startX.value > endX + 50) {
    nextSlide();
  } else if (startX.value < endX - 50) {
    prevSlide();
  }
};

/*const autoRotate = () => {
  setInterval(nextSlide, 6000);
};*/

window.addEventListener('resize', () => {
  currentSlide.value = 0; // Reset to first slide on resize to avoid layout issues
});

onMounted(() => {
  //autoRotate();
});
</script>

<style scoped>
.videos {
  padding: 50px 20px;
  text-align: center;
}

h2 {
  margin-bottom: 20px;
  font-size: 2rem;
}

.carousel-wrapper {
  position: relative;
  max-width: 80%;
  margin: 0 auto;
}

.carousel-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;
}

.carousel-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  cursor: pointer;
  z-index: 10;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.carousel-control:hover {
  transform: translateY(-50%) scale(1.1);
}

.carousel-control:active {
  transform: translateY(-50%) scale(1);
}

.carousel-control.left {
  left: -20px;
}

.carousel-control.right {
  right: -20px;
}

.carousel-track-container {
  overflow: hidden;
  width: 100%;
}

.carousel-track {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.carousel-slide {
  flex: 0 0 33.33%;
  box-sizing: border-box;
  padding: 0 10px;
  display: flex;
  justify-content: center;
}

.carousel-slide iframe,
.carousel-slide video {
  width: 100%;
  height: 15rem;
  border-radius: 10px;
  object-fit: cover; /* Ensures that the poster image covers the entire video area */
}

@media (max-width: 767px) {
  .carousel-slide {
    flex: 0 0 100%;
  }

  .carousel-slide iframe,
  .carousel-slide video {
    height: 41.25vw; /* Maintain a 16:9 aspect ratio */
  }
}
</style>
