<template>
  <section class="sponsors-section">
    <div class="sponsor-logos">
      <img v-for="(sponsor, index) in sponsors" :key="index" :src="sponsor.logo" :alt="sponsor.name">
    </div>
  </section>
</template>

<script setup>
import sponsor1 from '@/assets/sponsor_1.svg';
import sponsor2 from '@/assets/sponsor_2.svg';
import sponsor3 from '@/assets/sponsor_3.svg';
import sponsor4 from '@/assets/sponsor_4.svg';
// Add more sponsor logos as needed

const sponsors = [
  { name: 'Sponsor 1', logo: sponsor1 },
  { name: 'Sponsor 2', logo: sponsor2 },
  { name: 'Sponsor 3', logo: sponsor3 },
  { name: 'Sponsor 4', logo: sponsor4 },
  // Add more sponsors
];
</script>

<style scoped>
.sponsors-section {
  padding: 15px 0;
  background-color: #f1f1f1; /* Light shade of gray */
  text-align: center;
}

.sponsor-logos {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
}

.sponsor-logos img {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  width: auto;
  height: 5rem;
  margin: 0 3rem;
}

@media (max-width: 600px) {
  .sponsors-section {
    padding: 15px 10px; /* Added 10px padding to the left and right */
  }

  .sponsor-logos {
    flex-wrap: wrap;
  }

  .sponsor-logos img {
    margin: 0 1rem;
    flex: 1 0 calc(33.33% - 2rem); /* 3 logos per row */
    height: 2.5rem; /* Adjust this value as needed to fit all logos in one row */
  }
}
</style>
